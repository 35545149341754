import AccountCode from './AccountCode.vue'
import AccountCodeCreate from './Create.vue'
import AccountCodeEdit from './Create.vue'
import AccountCodeShow from './Show.vue'

export default [
    {
        path: '/accountCode',
        name: 'AccountCode',
        component: AccountCode
    },
    {
        path: '/accountCode/create',
        name: 'AccountCodeCreate',
        component: AccountCodeCreate
    },
    {
        path: '/accountCode/edit/:id',
        name: 'AccountCodeEdit',
        component: AccountCodeEdit
    },
    {
        path: '/accountCode/:id',
        name: 'AccountCodeShow',
        component: AccountCodeShow
    }
]
